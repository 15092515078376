import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import { LayoutContext, SCREENTYPE } from "@/hooks/useLayout";
import { path } from "@/utils/path";

import { VARIANT } from "@/ui/bouton";
import BoutonLink from "@/ui/boutonLink";
import Pub from "@/ui/pub";
import { PUBFORMATS } from "@/ui/pub/formats";
import UiDesktop from "./uiDesktop";
import UiMobile from "./uiMobile";

import styles from "./contenuesRecents.module.scss";

import {
    pushEmissionViewItemListToDataLayer,
    pushEmissionSelectItemExploreToDataLayer,
} from "@/utils/dataLayer/accueil-emissions-recentes";
import { Viewport } from "@/types/enum";
import { AccueilRecent } from "@/types/pages/accueil";
import { useInView } from "react-intersection-observer";

type ContenuRecentsProps = {
    className?: string;
    contenus: AccueilRecent[];
    viewport: Viewport;
};

const ContenuesRecents = ({ className = "", contenus, viewport }: ContenuRecentsProps) => {
    const { screenType } = useContext(LayoutContext);
    const [isMobile, setIsMobile] = useState(true);
    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(() => {
        setIsMobile(viewport == SCREENTYPE.MOBILE || screenType == SCREENTYPE.MOBILE);
    }, [screenType]);

    useEffect(() => {
        if (contenus && contenus.length > 0 && inView)
            pushEmissionViewItemListToDataLayer(
                "accueil-emissions-recentes",
                "Accueil Emission Recentes",
                contenus,
            );
    }, [contenus]);

    const emissionsPopulaireLink = path.fill(path.EMISSIONS);

    return (
        contenus &&
        contenus.length > 0 && (
            <section
                ref={ref}
                className={classNames(styles.me, {
                    [className]: className,
                })}
            >
                <div className="body">
                    <h2 className={styles.title}>Nos émissions récentes</h2>

                    <>
                        {isMobile ? (
                            <UiMobile contenus={contenus} />
                        ) : (
                            <UiDesktop contenus={contenus} />
                        )}
                    </>

                    <div className={styles.buttonLink}>
                        <BoutonLink
                            href={emissionsPopulaireLink}
                            variant={VARIANT.PRIMARY}
                            onClick={pushEmissionSelectItemExploreToDataLayer}
                        >
                            Explorer les émissions
                        </BoutonLink>
                    </div>
                </div>
   
            </section>
        )
    );
};

export default ContenuesRecents;
