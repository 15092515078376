"use client";
import React from "react";
import { Theme } from "@/types/components/select";
import Select, { GroupBase, Props } from "react-select";

import type {} from "react-select/base";
// This import is necessary for module augmentation.
// It allows us to extend the 'Props' interface in the 'react-select/base' module
// and add our custom property 'myCustomProp' to it.

declare module "react-select/base" {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > {
    singleValue: boolean;
  }
}

const SelectForm = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group>
) => {
  const customSelectStyles = {
    dropdownIndicator: (provided: object) => ({
      ...provided,
      color: "#272727",
      fontSize: "12px",
      width: "32px",
    }),
    control: (provided: object) => ({
      ...provided,
      backgroundColor: "#e5e5e5",
      border: "0 none",
      color: "#272727",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "600",
      paddingLeft: "2px",
    }),
    indicatorSeparator: (provided: object) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "0 none",
      width: "0",
    }),
    option: (provided: object) => ({
      ...provided,
      cursor: "pointer",
    }),
    menu: (provided: object) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  return (
    <Select
      styles={customSelectStyles}
      theme={(theme: Theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: "#005b5f",
        },
      })}
      aria-label="Sélectionner"
      {...props}
    />
  );
};

export default SelectForm;
