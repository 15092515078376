import React from "react";
import Layout from "@/layout";
import { NextSeo } from "next-seo";
import Carrousel from "@/pages/home/carrousel";
import RecettesPopulaires from "@/pages/home/recettesPopulaires";
import ContenuesRecents from "@/pages/home/contenuesRecents";
import QuoiCuisiner from "@/pages/home/quoiCuisiner";
import Infolettre from "@/pages/home/infolettre";
import Arrivages from "@/pages/home/arrivages";
import QuoiLire from "@/pages/home/quoiLire";
import { getSEONoFollow, getSEONoIndex } from "@/utils/nextjs/seo";
import { swrFetcher } from "@/utils/request";
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import { Viewport } from "@/types/enum";
import {
    AccueilArrivages,
    AccueilCarousel,
    AccueilQuoiCuisiner,
    AccueilRecent,
} from "@/types/pages/accueil";
import { RecetteCompact } from "@/types/recette";
import { PublicationCompact } from "@/types/publication";
import Pub from "@/ui/pub";
import { PUBFORMATS } from "@/ui/pub/formats";

const Home = ({
    viewport,
    carousel,
    recettesPopulaires,
    recents,
    quoiCuisiner,
    arrivages,
    quoiLire,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => (
    <Layout mainBody={false} viewport={viewport}>
        <NextSeo
            description="Cuisinez, la plateforme culinaire de Télé-Québec, vous propose une panoplie de recettes délicieuses, des techniques et des astuces et vos émissions de cuisine préférées!"
            nofollow={getSEONoFollow()}
            noindex={getSEONoIndex()}
        />

        <div>
            <Carrousel contenus={carousel} viewport={viewport} />
            <RecettesPopulaires contenus={recettesPopulaires} viewport={viewport} />
            <ContenuesRecents contenus={recents} viewport={viewport} />
            <div style={{backgroundColor: "#f2f2f2"}}>
                <Pub format={PUBFORMATS.leaderboardBillboard} />
            </div>
            <QuoiCuisiner contenus={quoiCuisiner} viewport={viewport} />
            <Infolettre />
            <Arrivages contenus={arrivages} viewport={viewport} />
            <hr style={{borderTop: "10px solid #005b5f", height: "0px", color: "#005b5f" }} />
            <QuoiLire contenus={quoiLire} />
            <div style={{backgroundColor: "#f2f2f2"}}>
                <Pub format={PUBFORMATS.leaderboardBillboardDouble} />
            </div>
        </div>
    </Layout>
);

export const getServerSideProps = async ({ query }: GetServerSidePropsContext) => {
    const api = process.env.SERVER_API_URL;

    const viewport = query["viewport"] || "desktop";

    try {
        const carouselResponse: AccueilCarousel[] = await swrFetcher(`${api}/v1/accueil/carousel`);
        const carousel = carouselResponse?.map((data) => ({
            ...data,
            type: data?.typeEntite || "sur-mesure",
            id: data?.entiteId || Math.round(Math.random() * 10000000),
        }));

        const recettesPopulaires: RecetteCompact[] = await swrFetcher(
            `${api}/v1/accueil/populaires?${new URLSearchParams({
                offset: "0",
                count: "6",
            })?.toString()}`,
        );
        const recents: AccueilRecent[] = await swrFetcher(`${api}/v1/accueil/recents`);
        const quoiCuisiner: AccueilQuoiCuisiner[] = await swrFetcher(
            `${api}/v1/accueil/categories-recettes`,
        );
        const arrivages: AccueilArrivages = await swrFetcher(`${api}/v1/accueil/arrivage`);
        const quoiLire: PublicationCompact[] = await swrFetcher(`${api}/v1/accueil/publications`);

        return {
            props: {
                viewport: viewport as Viewport,
                carousel,
                recettesPopulaires,
                recents,
                quoiCuisiner,
                arrivages,
                quoiLire,
            },
        };
    } catch {
        console.error("Erreur lors de la récupération des données de la page d'accueil");
    }

    return {
        redirect: {
            destination: "/500",
            permanent: false,
        },
    };
};

export default Home;
