/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useState } from "react";
import classNames from "classnames";

import SliderAlmostFull from "../../../../ui/sliderAlmostFull";
import Select from "../../../../ui/form/select";
import Carte from "../../../../ui/cartes/carte";

import { pushRepasSelectItemToDataLayer } from "../../../../utils/dataLayer/accueil-prochains-repas";

import styles from "./uiMobile.module.scss";
import { AccueilQuoiCuisiner } from "../../../../types/pages/accueil";
import { SwiperSlide } from "swiper/react";
import { SingleValue } from "react-select";

const UiMobile = ({
    className = "",
    contenus,
}: {
    className?: string;
    contenus: AccueilQuoiCuisiner[];
}) => {
    const categoryLastSlice = contenus && contenus.length >= 1 ? contenus.slice(0) : null;
    const categoryLast =
        categoryLastSlice &&
        typeof categoryLastSlice[0] !== "undefined" &&
        typeof categoryLastSlice[0].id !== "undefined"
            ? categoryLastSlice[0].id
            : null;

    const [categoryId, setCategoryId] = useState(categoryLast);
    const changeCategoryId = (option: SingleValue<{ value: number; label: string; }>) => {
        const value = option?.value || null;
        if (value) {
            setCategoryId(Number(value));
        }
    };

    const categoryOptions: { value: number; label: string }[] = [];
    contenus?.forEach((contenu) => {
        const { id, nom } = contenu;
        categoryOptions.push({ value: id, label: nom });
    });

    return (
        <div
            className={classNames(
                styles.me,
                {
                    [className]: className,
                },
                "body",
            )}
        >
            <div className={styles.categoryList}>
                {categoryOptions && categoryOptions.length > 1 && (
                    <div className={styles.tabsList}>
                        <Select
                            options={categoryOptions}
                            defaultValue={categoryOptions[0]}
                            isSearchable
                            singleValue
                            onChange={changeCategoryId}
                        />
                    </div>
                )}
            </div>
            <div className={styles.recettesList}>
                {contenus &&
                    contenus.length > 0 &&
                    contenus.map((contenu) => {
                        const { id: idContenu, recettes } = contenu;

                        if (idContenu !== categoryId || !recettes || recettes.length === 0) {
                            return null;
                        }

                        return (
                            <React.Fragment key={`contentQuoiCuisinerUiMobile-${idContenu}`}>
                                <SliderAlmostFull>
                                    {recettes.map((recette) => {
                                        if (!recette) {
                                            return null;
                                        }

                                        const { id: recetteId } = recette;
                                        return (
                                            <SwiperSlide
                                                key={`contentQuoiCuisinerUiMobile-recette-${recetteId}`}
                                            >
                                                <div
                                                    className={styles.block}
                                                    onClick={() => {
                                                        pushRepasSelectItemToDataLayer(
                                                            "accueil-prochains-repas",
                                                            "Accueil Prochains Repas",
                                                            recette,
                                                            contenu.nom,
                                                        );
                                                    }}
                                                >
                                                    <Carte contenu={recette} />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                </SliderAlmostFull>
                            </React.Fragment>
                        );
                    })}
            </div>
        </div>
    );
};

export default UiMobile;
